import React from "react";
import pdfFile from "../../pdfFiles/pdf1.pdf";
import { AiFillFilePdf } from "react-icons/ai";


function InternalDocPage() {
  return (
    <div style={{padding: '20px'}}>
        <h3>Устав АО "Национальный клиринговый центр"</h3>
      <a href={pdfFile} target="_blank" rel="pdf-file noreferrer">
        <AiFillFilePdf className="pdf-file"/>
      </a>
    </div>
  );
}

export default InternalDocPage;
