import React from 'react'
import UnderConstruction from '../../components/underConstruction/underConstruction'

function ServicesPage() {
  return (
    <>
      <UnderConstruction />
    </>
  )
}

export default ServicesPage