import React from "react";
import {Row, Col} from 'antd'
import pdfFile5 from "../../pdfFiles/pdf5.pdf";
import pdfFile6 from '../../pdfFiles/pdf6.pdf'
import pdfFile7 from "../../pdfFiles/pdf7.pdf"
import pdfFile8 from "../../pdfFiles/pdf8.pdf"
import pdfFile9 from "../../pdfFiles/pdf9.pdf"
import pdfFile10 from "../../pdfFiles/pdf10.pdf"
import pdfFile11 from "../../pdfFiles/pdf11.pdf"
import pdfFile12 from "../../pdfFiles/pdf12.pdf"
import pdfFile13 from "../../pdfFiles/pdf13.pdf"
import { AiFillFilePdf } from "react-icons/ai";

function RulesPage() {
    // Правила_проведения_торгов_облигациями_ЦБ_на_УзРВБ
  return (
    <div style={{ padding: "20px" }}>
      <h1>Правила</h1>
      <Row className="row">
      <Col flex="1 0 20%" className="column-pdf-text">
      <a href={pdfFile5} target="_blank" rel="pdf-file noreferrer">
        <AiFillFilePdf className="pdf-file"/>
      </a>
      <p className="pdf-text" style={{fontWeight: 500, fontSize: '18px'}}>Правила биржевых торгов УзРВБ по купле продаже иностранной валюты</p>
      </Col>

      <Col flex="1 0 20%" className="column-pdf-text"><a href={pdfFile6} target="_blank" rel="pdf-file noreferrer">
        <AiFillFilePdf className="pdf-file"/>
      </a>
      <p className="pdf-text" style={{fontWeight: 500, fontSize: '18px'}}>Правила биржевых торгов УзРВБ по проведению операций валютными фьючерсными</p>
      </Col>

      <Col flex="1 0 20%" className="column-pdf-text"><a href={pdfFile7} target="_blank" rel="pdf-file noreferrer">
        <AiFillFilePdf className="pdf-file"/>
      </a>
      <p className="pdf-text" style={{fontWeight: 500, fontSize: '18px'}}>Правила заключения и исполнения сделок РЕПО с ГЦБ на УзРВБ</p>
      </Col>

      <Col flex="1 0 20%" className="column-pdf-text"><a href={pdfFile8} target="_blank" rel="pdf-file noreferrer">
        <AiFillFilePdf className="pdf-file"/>
      </a>
      <p className="pdf-text" style={{fontWeight: 500, fontSize: '18px'}}>Правила осуществления клиринга и проведения расчётов на валютном</p>
      </Col>

      <Col flex="1 0 20%" className="column-pdf-text"><a href={pdfFile9} target="_blank" rel="pdf-file noreferrer">
        <AiFillFilePdf className="pdf-file"/>
      </a>
      <p className="pdf-text" style={{fontWeight: 500, fontSize: '18px'}}>Правила проведения депозитных операций Центрального банка с коммерческими</p>
      </Col>

      <Col flex="1 0 20%" className="column-pdf-text"><a href={pdfFile10} target="_blank" rel="pdf-file noreferrer">
        <AiFillFilePdf className="pdf-file"/>
      </a>
      <p className="pdf-text" style={{fontWeight: 500, fontSize: '18px'}}>Правила проведения операций валютного свопа на УзРВБ2 26 06 2023</p>
      </Col>

      <Col flex="1 0 20%" className="column-pdf-text"><a href={pdfFile11} target="_blank" rel="pdf-file noreferrer">
        <AiFillFilePdf className="pdf-file"/>
      </a>
      <p className="pdf-text" style={{fontWeight: 500, fontSize: '18px'}}>Правила проведения операций посредством электронной торговой платформы</p>
      </Col>

      <Col flex="1 0 20%" className="column-pdf-text"><a href={pdfFile11} target="_blank" rel="pdf-file noreferrer">
        <AiFillFilePdf className="pdf-file"/>
      </a>
      <p className="pdf-text" style={{fontWeight: 500, fontSize: '18px'}}>Правила проведения операций посредством электронной торговой платформы</p>
      </Col>

      <Col flex="1 0 20%" className="column-pdf-text"><a href={pdfFile12} target="_blank" rel="pdf-file noreferrer">
        <AiFillFilePdf className="pdf-file"/>
      </a>
      <p className="pdf-text" style={{fontWeight: 500, fontSize: '18px'}}>Правила проведения торгов государственными ценными бумагами на УзРВБ</p>
      </Col>

      <Col flex="1 0 20%" className="column-pdf-text"><a href={pdfFile13} target="_blank" rel="pdf-file noreferrer">
        <AiFillFilePdf className="pdf-file"/>
      </a>
      <p className="pdf-text" style={{fontWeight: 500, fontSize: '18px'}}>Правила проведения торгов облигациями ЦБ на УзРВБ</p>
      </Col>

    </Row>
    </div>
  );
}

export default RulesPage;
