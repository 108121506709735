  import React from 'react'
  import UnderConstruction from '../../components/underConstruction/underConstruction'

function GovProcurementPage() {
  return (
    <>
      <UnderConstruction />
    </>
  )
}

export default GovProcurementPage