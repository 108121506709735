import React from 'react'
import UnderConstruction from '../../components/underConstruction/underConstruction'

function ManagementRiskPage() {
  return (
    <>
    <UnderConstruction />
    </>
  )
}

export default ManagementRiskPage