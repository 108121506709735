import React from "react";
import {Row, Col} from 'antd'
import pdfFile from "../../pdfFiles/pdf3.pdf";
import pdfFile1 from '../../pdfFiles/pdf4.pdf'
import pdfFile2 from '../../pdfFiles/MKM_muhim_fakt_1.pdf'
import pdfFile3 from '../../pdfFiles/MKM_muhim_fakt_2.pdf'
import pdfFile4 from '../../pdfFiles/MKM_muhim_fakt_3.pdf'
import pdfFile5 from '../../pdfFiles/MKM_muhim_fakt_4.pdf'
import { AiFillFilePdf } from "react-icons/ai";

function EssentialFactsPage() {
  return (
    <div style={{ padding: "20px" }}>
      <h1>Существенные факты</h1>
      <Row className="row">
      <Col flex="1 0 33.3%" className="column-pdf-text">
      <a href={pdfFile} target="_blank" rel="pdf-file noreferrer">
        <AiFillFilePdf className="pdf-file" style={{color: '#E93131'}}/>
      </a>
      <p className="pdf-text" style={{fontWeight: 500, fontSize: '18px'}}>ИНФОРМАЦИЯ О СУЩЕСТВЕННОМ ФАКТЕ №6</p>
      </Col>
      <Col flex="1 0 33.3%" className="column-pdf-text">
      <a href={pdfFile1} target="_blank" rel="pdf-file noreferrer">
        <AiFillFilePdf className="pdf-file" style={{color: '#E93131'}}/>
      </a>
      <p className="pdf-text" style={{fontWeight: 500, fontSize: '18px'}}>ИНФОРМАЦИЯ О СУЩЕСТВЕННОМ ФАКТЕ №36</p>
      </Col>

      <Col flex="1 0 33.3%" className="column-pdf-text">
      <a href={pdfFile2} target="_blank" rel="pdf-file noreferrer">
        <AiFillFilePdf className="pdf-file" style={{color: '#E93131'}}/>
      </a>
      <p className="pdf-text" style={{fontWeight: 500, fontSize: '18px'}}>Информация о существенном факте №25 от 10.11.2023 (выпуск ценных бумаг)</p>
      </Col>

      <Col flex="1 0 33.3%" className="column-pdf-text">
      <a href={pdfFile3} target="_blank" rel="pdf-file noreferrer">
        <AiFillFilePdf className="pdf-file" style={{color: '#E93131'}}/>
      </a>
      <p className="pdf-text" style={{fontWeight: 500, fontSize: '18px'}}>Информация о существенном факте №6 от 08.01.2024 (размещение средств на депозиты)</p>
      </Col>

      <Col flex="1 0 28%" className="column-pdf-text">
      <a href={pdfFile4} target="_blank" rel="pdf-file noreferrer">
        <AiFillFilePdf className="pdf-file" style={{color: '#E93131'}}/>
      </a>
      <p className="pdf-text" style={{fontWeight: 500, fontSize: '18px'}}>Информация о существенном факте №6 от 08.01.2024 (утверждение бизнес-плана, аренда помещений)</p>
      </Col>


      <Col flex="1 0 28%" className="column-pdf-text">
      <a href={pdfFile5} target="_blank" rel="pdf-file noreferrer">
        <AiFillFilePdf className="pdf-file" style={{color: '#E93131'}}/>
      </a>
      <p className="pdf-text" style={{fontWeight: 500, fontSize: '18px'}}>Информация о существенном факте №6 от 06.01.2024 (утверждение положений)</p>
      </Col>

      

    </Row>
    </div>
  );
}

export default EssentialFactsPage;
