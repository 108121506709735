import React from "react";
import { Row, Col } from "antd";
// import pdfFile from "../../pdfFiles/pdf3.pdf";
// import pdfFile1 from "../../pdfFiles/pdf4.pdf";
import pdfFile2 from "../../pdfFiles/АУЙ 2 сон баён.pdf";
import pdfFile3 from "../../pdfFiles/АУЙ 3 сон баён.pdf";
import pdfFile4 from "../../pdfFiles/АУЙ 4 сон баён.pdf";
import pdfFile5 from "../../pdfFiles/АУЙ 5 сон баён.pdf";
import { AiFillFilePdf } from "react-icons/ai";

function extractsFromDecOsaBodiesPage() {
  return (
    <div style={{ padding: "20px" }}>
      <h1>Выписки из решений ОСА</h1>
      <Row className="row">
        <Col flex="1 0 25%" className="column-pdf-text">
          <a href={pdfFile2} target="_blank" rel="pdf-file noreferrer">
            <AiFillFilePdf className="pdf-file" style={{ color: "#E93131" }} />
          </a>
          <p className="pdf-text" style={{ fontWeight: 500, fontSize: "18px" }}>
            Выписка из решения №2 единственного акционера АО ”Национальный
            клиринговый центр" (узб.)
          </p>
        </Col>
        <Col flex="1 0 25%" className="column-pdf-text">
          <a href={pdfFile3} target="_blank" rel="pdf-file noreferrer">
            <AiFillFilePdf className="pdf-file" style={{ color: "#E93131" }} />
          </a>
          <p className="pdf-text" style={{ fontWeight: 500, fontSize: "18px" }}>
            Выписка из решения №3 единственного акционера АО ”Национальный
            клиринговый центр" (узб.)
          </p>
        </Col>
      </Row>
      <Row style={{ marginTop: 36 }}>
        <Col flex="1 0 25%" className="column-pdf-text">
          <a href={pdfFile4} target="_blank" rel="pdf-file noreferrer">
            <AiFillFilePdf className="pdf-file" style={{ color: "#E93131" }} />
          </a>
          <p className="pdf-text" style={{ fontWeight: 500, fontSize: "18px" }}>
            Выписка из решения №4 единственного акционера АО ”Национальный
            клиринговый центр" (узб.)
          </p>
        </Col>
        <Col flex="1 0 25%" className="column-pdf-text">
          <a href={pdfFile5} target="_blank" rel="pdf-file noreferrer">
            <AiFillFilePdf className="pdf-file" style={{ color: "#E93131" }} />
          </a>
          <p className="pdf-text" style={{ fontWeight: 500, fontSize: "18px" }}>
            Выписка из решения №5 единственного акционера АО ”Национальный
            клиринговый центр" (узб.)
          </p>
        </Col>
      </Row>
    </div>
  );
}

export default extractsFromDecOsaBodiesPage;
