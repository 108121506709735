import React from 'react'
import UnderConstruction from '../../components/underConstruction/underConstruction'

function ContactPage() {
  return (
    <div>
        <UnderConstruction />
    </div>
  )
}

export default ContactPage