import React from 'react'
import UnderConstruction from '../../components/underConstruction/underConstruction'

function InfoAcquisSharesPage
() {
  return (
    <>
        <UnderConstruction />
    </>
  )
}

export default InfoAcquisSharesPage
