import React from 'react'

function UnderConstruction() {
  return (
    <div style={{
        minHeight: '65vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        }}>
          <h1 style={{fontSize: '40px'}}>Страница в разработке</h1>
  
          <p style={{fontSize: '20px', fontWeight: 600}}>
          Благодарим вас за посещение! В данный момент эта страница находится в стадии разработки и в скором времени мы представим её.
          </p>
  
          <p style={{fontSize: '18px', fontWeight: 600}}>Если у вас есть срочные вопросы <a href="mailto: info@uzce-ncc.uz" className='mail-to'> свяжитесь с нами</a></p>
  
          <p style={{fontFamily: `Georgia, serif;`, fontWeight: 400, fontSize: '16px'}}>Команда разработчиков UZCE</p>
      </div>
  )
}

export default UnderConstruction