import { Table } from "antd";

function ShareHolder() {
  const columns = [
    {
      title: "Наименование акционера",
      dataIndex: "shareholder_name",
    },
    {
      title: "Местонахождение и почтовый адрес",
      dataIndex: "personal_info",
    },
    {
      title: "Веб-сайт",
      dataIndex: "website",
      render: (website) => (
        <a href={`https://${website}`} target="_blank">
          {website}
        </a>
      ),
    },
  ];
  const data = [
    {
      shareholder_name:
        "Акционерное общество «Узбекская республиканская валютная биржа»",
      personal_info:
        "100084, Республика Узбекистан, город Ташкент, проспект Ш. Рашидова, 4.",
      website: "www.uzrvb.uz",
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
      <h1>Состав акционеров</h1>
      <Table columns={columns} dataSource={data} pagination={false} />
    </div>
  );
}

export default ShareHolder;
