import React from 'react'
import UnderConstruction from '../../components/underConstruction/underConstruction'

function AnnouncementShareholdersPage() {
  return (
    <>
    <UnderConstruction />
</>
  )
}

export default AnnouncementShareholdersPage