import React from 'react'
import UnderConstruction from '../../components/underConstruction/underConstruction'

function HowToBecomeMemberPage() {
  return (
    <>
      <UnderConstruction />   
    </>
  )
}

export default HowToBecomeMemberPage