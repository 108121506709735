import React from "react";
import { Table, Typography } from "antd";
import './index.css';
const { Text } = Typography;
const columns = [
  {
    title: "",
    dataIndex: "name",
  },
  {
    title: "",
    dataIndex: "borrow",
    
  },
];
const data = [
  {
    key: "1",
    name: "Полное наименование на узбекском языке",
    borrow: `"MILLIY KLIRING MARKAZI" AKSIYADORLIK JAMIYATI`,
  },
  {
    key: "2",
    name: "Краткое наименование",
    borrow: `"MILLIY KLIRING MARKAZI" AJ`,
  },
  {
    key: "3",
    name: "Почтовый адрес",
    borrow: "г. Ташкент, Юнусабадский район, пр.Ш.Рашидова, 4.",
  },
  {
    key: "4",
    name: "Местонахождение",
    borrow: "г. Ташкент, Юнусабадский район, пр.Ш.Рашидова, 4.",
  },
  {
    key: "5",
    name: "ИНН",
    borrow: "310 912 133",
  },
  {
    key: "6",
    name: "Сертификат регистрации",
    borrow: "№ 2308334 от 08.11.2023 года, отдел Юнусабада Центра Государственных услуг",
  },
  {
    key: "7",
    name: "Международный код идентификации юридического лица, pre-LEI",
    borrow: "-",
  },
  {
    key: "8",
    name: "SWIFT",
    borrow: "-",
  },
  {
    key: "10",
    name: "Телефон",
    borrow: "+998 (55) 501-33-02",
  },
  {
    key: "9",
    name: "Факс",
    borrow: "+998 (55) 501-33-02",
  },
  {
    key: "11",
    name: "e-mail",
    borrow: "info@uzce-ncc.uz",
  },

];


function RequisitesPage() {
  return (
    <div style={{ padding: "50px" }}>
      <h2 style={{display: 'flex'}}>Реквизиты</h2>

      <Table columns={columns} dataSource={data} pagination={false}  />
    </div>
  );
}

export default RequisitesPage;
