import React from 'react'
import UnderConstruction from '../../components/underConstruction/underConstruction'

function VacanciesPage() {
  return (
    <>
    <UnderConstruction />
    </>
  )
}

export default VacanciesPage