import React, { useState } from "react";
import { Layout, Menu, theme, Divider, Flex } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { FcFilingCabinet } from "react-icons/fc";
import { Link } from "react-router-dom";
import {
  FacebookOutlined,
  InstagramOutlined,
  SearchOutlined,
  SendOutlined,
  TwitterOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import { Input } from "antd";
import logoHomeImage from "../../images/ncc_logo-01.png";
import telegram from "../../images/tg.png";

const { Header, Content, Footer } = Layout;

const Navbar = ({ children }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const location = useLocation();

  function getItem(label, key, children) {
    return {
      key,
      children,
      label,
    };
  }
  const [activeMenu, setActiveMenu] = useState([
    location.pathname.replace("/", ""),
  ]);
  const navigate = useNavigate();

  // const homeImage = <img alt="home" src={logoHomeImage} style={{height: 120, width: 150, position: "relative"}}/>
  // const homeImage = <img alt="home" src={logoHomeImage} style={{height: "10vh", width: "20vw", position: "relative", top: '16px'}}/>

  const items = [
    // getItem(homeImage, ""),
    getItem("О нас", "about", [
      getItem("Корпоративное управление", "corporate-gover", [
        getItem("Состав акционеров", "shareholder"),
        getItem("Руководство", "management"),
        getItem("Организационная структура", "org-structure"),
        getItem("Услуги", "services"),
      ]),
      getItem("Открытые данные", "open-data", [
        getItem("Информация об имуществе", "property-info"),
        getItem("Госзакупки", "gov-procurement"),
        getItem("Годовая смета расходов и ее реализация", "annual-cost"),
      ]),
      getItem("Вакансии", "vacancies"),
      getItem("Свидетельство о регистрации", "registration-proof"),
      getItem("Реквизиты", "requisites"),
    ]),
    getItem("Участникам клиринга", "clearing-participants", [
      getItem("Как стать участником", "how-to-become-member"),
      getItem("Особенности клиринга", "clearing-features"),
      getItem("Участники клиринга", "clearing-participants"),
      getItem("Тарифы", "rates"),
      getItem("Документы", "docs"),
      getItem("Контакты", "contacts"),
    ]),
    getItem("Пресс-центр", "12", [
      getItem("Новости и объявление", "news-announcement"),
      getItem("Пресс-релизы", "press-releases"),
      getItem("Медиатека", "media-library"),
      getItem("Архив новостей", "news-archive"),
    ]),
    getItem("Корпоративная деятельность", "13", [
      getItem("Структура уставного капитала", "auth-cap-struc"),
      getItem("Коллегиальные и совещательные органы", "col-advisory-bodies"),
      getItem("Существенные факты", "essential-facts"),
      getItem("Аффилированные лица", "affiliates"),
      getItem("Комплаенс", "compliance", [
        getItem(
          "Нормативные-правовые и внутренние документы",
          "reg-leg-inter-doc"
        ),
        getItem("Отчеты", "corp-reports"),
      ]),
      getItem("Управление рисками", "management-risk"),
    ]),
    getItem("Акционерам", "14", [
      getItem("Отчетность", "shareholder-reports"),
      getItem("Бизнес план", "business-plan"),
      getItem("Аудиторские заключения", "audit-reports"),
      getItem("Ключевые показатели эффективности", "kpi"),
      getItem("Сведения о приобретении акций", "info-acquis-shares"),
      getItem("Выписки из решений ОСА", "extracts-from-dec-osa-bodies"),
      getItem(
        "Сведения о выплатах органам управления",
        "info-pay-management-bodies"
      ),
      getItem("Проспекты эмиссии", "prospectuses"),
      getItem("Сведения о дивидендах", "info-about-dividends"),
      getItem("Объявления для акционеров", "announcement-shareholders"),
    ]),
    getItem("Нормативная база", "15", [
      getItem("Законодательство", "legislation"),
      getItem("Правила", "rules"),
      getItem("Внутренние документы", "internal-doc"),
      getItem("Формы документов", "doc-forms"),
    ]),

    getItem("Обратная связь", "16", [
      getItem("Онлайн формы обращений", "online-req-form"),
      getItem("Онлайн-консультант", "online-consultant"),
      getItem("Часто задаваемые вопросы", "faq"),
    ]),
  ];

  const onClick = (e) => {
    setActiveMenu(e.key);
    navigate(`/${e.key}`);
  };

  return (
    <Layout className="layout" style={{ minHeight: "100vh" }}>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          background: "#EAEEF3",
          // background: "#EAEEF3",
          position: "relative",
        }}
      >
        <div className="demo-logo">
          <Link to="/" onClick={() => setActiveMenu([])}>
            <img
              alt="home"
              src={logoHomeImage}
              style={{
                height: 80,
                width: 150,
                position: "relative",
                marginTop: 24,
                marginRight: 24,
              }}
            />
          </Link>
        </div>
        <Menu
          defaultSelectedKeys={["0"]}
          selectedKeys={activeMenu}
          mode="horizontal"
          items={items}
          onClick={onClick}
          style={{
            width: "100%",
            background: "#EAEEF3",
            color: "#3D3D43",
            fontWeight: 600,
          }}
        />

        <div className="cabinet-search">
          <div className="serach">
            <Input
              size="medium"
              placeholder="Search"
              suffix={<SearchOutlined />}
              classNames="input-search"
            />
          </div>
          <a
            className="cabinet"
            href="https://cabinet.uzrvb.uz/login"
            target="_b"
          >
            <p className="cabinet-text" style={{ color: "#3D3D43" }}>
              Персональный кабинет
            </p>
            <FcFilingCabinet
              style={{ fontSize: "30px", color: "red" }}
              className="cabinet-icon"
            />
          </a>
        </div>
      </Header>
      <Content
        style={{
          padding: "0 50px",
        }}
      >
        <div
          className="site-layout-content"
          style={{
            background: colorBgContainer,
          }}
        >
          {children}
        </div>
      </Content>
      <Footer
        style={{
          textAlign: "center",
          backgroundColor: "#EAEEF3",
          color: "#3D3D43",
          fontWeight: 600,
        }}
      >
        <div>
          <Flex justify="space-around">
            <Link style={{ color: "#3D3D43" }} to="/requisites">
              Реквизиты НКЦ
            </Link>
            <Link style={{ color: "#3D3D43" }} to="#">
              Правила клиринга
            </Link>
            <Link style={{ color: "#3D3D43" }} to="#">
              Органы управления
            </Link>
            <Link style={{ color: "#3D3D43" }} to="#">
              Раскрытие информации
            </Link>
            <Link style={{ color: "#3D3D43" }} to="#">
              Карта сайта
            </Link>
          </Flex>
          <br />
          <Flex justify="space-around">
            <div
              style={{
                fontSize: "20px",
              }}
            >
              info@uzce-ncc.uz
            </div>
            <div
              style={{
                fontSize: "20px",
              }}
            >
              +998 (55) 501-33-02
            </div>
            {/* <div>
              <Flex justify="center" gap={8}>
                <div>
                  <FacebookOutlined
                    className="s-icon"
                  />
                </div>
                <div>
                  <InstagramOutlined
                    className="s-icon"
                  />
                </div>
                <div>
                  <img
                    className="telegram"
                    src={telegram}
                    alt="telegram"
                  />
                </div>
                <div>
                  <TwitterOutlined
                    className="s-icon"
                  />
                </div>
                <div>
                  <YoutubeOutlined
                    className="s-icon"
                  />
                </div>
              </Flex>
            </div> */}
          </Flex>
        </div>
        <Divider style={{ backgroundColor: "#A1A6B0" }} />
        <div>
          <p>
            АО "Национальный клиринговый центр" Все права защищены. При
            использовании материалов сайта ссылка на сайт{" "}
            <span style={{ fontWeight: 700, fontSize: 15 }}> uzce-ncc.uz </span>
            обязательна.
          </p>

          <p>Copyright © UZCE-NCC 2023.</p>
        </div>
      </Footer>
    </Layout>
  );
};
export default Navbar;
