import { Table } from "antd";

function AuthCapStructure() {
  const columns = [
    {
      title: "Наименование",
      dataIndex: "name",
    },
    {
      title: "Местонахождение и почтовый адрес",
      dataIndex: "personal_info",
    },
    {
      title: "Количество и тип акций",
      dataIndex: "quantity_type_auction",
    },
    {
      title: "Голосующие акции (%)",
      dataIndex: "vote_auction",
    },
  ];
  const data = [
    {
      name: "Акционерное общество «Узбекская республиканская валютная биржа»",
      personal_info:
        "100084, Республика Узбекистан, город Ташкент, проспект Ш. Рашидова, 4.",
      quantity_type_auction: "5 000 000 шт., именных простых",
      vote_auction: "100,00",
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
      <h1>Структура уставного капитала</h1>
      <Table columns={columns} dataSource={data} pagination={false} />
    </div>
  );
}

export default AuthCapStructure;
