import { Button } from "antd";
import { Link } from "react-router-dom";
import notFound from '../../images/404-uz-rvb-web.png'

const NotFound = () => {
  return (
    <div className="not-found-bg">
      <img className="not-found-page" src={notFound} alt="not found page"/>
      <div style={{fontSize: 66}}>
        <span style={{color: '#2F2E41'}}>4</span>
        <span style={{color: '#00B0FF'}}>0</span>
        <span style={{color: '#2F2E41'}}>4</span>
        <br />
        <div>
        Страница не найдена
        </div>
      </div>
      <div>
        <Link to="/">
          <Button
            type="primary"
            size="large"
            style={{
                backgroundColor: '#00B0FF',
                marginTop: '60px'
            }}
          >
            Перейти в главную страницу
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default NotFound;