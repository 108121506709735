import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Main from './pages/mainPage/index'
import About from './pages/aboutPage/index'
import ShareHolder from "./pages/shareholdersPage";
import ManagementPage from "./pages/managementPage";
import OrganizationalPage from "./pages/organizationalPage";
import ServicesPage from "./pages/servicesPage";
import PropertyInfoPage from "./pages/propertyInfoPage";
import GovProcurementPage from './pages/govProcurementPage';
import AnnualCostPage from "./pages/annualCostPage";
import VacanciesPage from "./pages/vacanciesPage";
import RequisitesPage from "./pages/requisitesPage"
import HowToBecomeMemberPage from "./pages/howToBecomeMemberPage";
import ClearingFeaturepage from "./pages/clearingFeaturePage/inex";
import ClearingParticipantsPage from "./pages/clearingParticipantsPage";
import RatesPage from "./pages/ratesPages"
import DocsPage from "./pages/docsPage";
import ContactPage from "./pages/contactPage";
import NewsAnnouncementPage from "./pages/newsAnnouncementPage";
import PressRealsePage from "./pages/pressRealsePage";
import MediaLibraryPage from "./pages/mediaLibraryPage";
import NewsArchivePage from "./pages/newsArchivePage";
import InternalDocPage from "./pages/internalDocPage";
import RegistrationProofPage from "./pages/registrationProofPage";
import EssentialFactsPage from "./pages/essentialFactsPage";
import RulesPage from './pages/rulesPage';
import AuthCapStructure from './pages/authCapStructurePage';
import ColAdvisoryBodiesPage from './pages/authCapStructurePage';
import AffiliatesPage from "./pages/affiliatesPage";
import RegLegInterDocPage from "./pages/regLegInterDocPage";
import CorpReportPage from "./pages/corpReportPage";
import ManagementRiskPage from "./pages/managementRiskPage";
import ShareHolderReport from "./pages/shareholderReportPage";
import BusinesPlanPage from "./pages/businessPlanPage";
import AuditReportPage from "./pages/auditReportPage";
import KPIPage from "./pages/kpiPage";
import InfoAcquisSharesPage from "./pages/infoAcquisSharesPage";
import ExtractsFromDecOsaBodiesPage from "./pages/extractsFromDecOsaBodiesPage";
import InfoPayManagementBodiesPage from "./pages/infoPayManagementBodiesPage";
import ProspectusesPage from "./pages/prospectusesPage";
import InfoAboutDividendsPage from './pages/infoAboutDividendsPage';
import AnnouncementShareholdersPage from './pages/announcementShareholdersPage';
import LegislationPage from "./pages/legislationPage";
import DocFormsPage from "./pages/docFormsPage"
import OnlineReqFormPage from "./pages/onlineReqFormPage";
import OnlineConsultantPage from "./pages/onlineConsultantPage"
import FAQPage from "./pages/faqPage"
import Wrapper from "./layout";
import NotFound from "./components/notFound/NotFound";
import './App.css';

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
      <Wrapper>
        <Main />
      </Wrapper>  
      )
    },
    {
      path: "/about",
      element: (
        <Wrapper>
          <About />
        </Wrapper>
      ),
    },
    {
      path: "/shareholder",
      element: (
        <Wrapper>
          <ShareHolder />
        </Wrapper>
      ),
    },
    {
      path: "/management",
      element: (
        <Wrapper>
          <ManagementPage />
        </Wrapper>
      ),
    },
    {
      path: "/org-structure",
      element: (
        <Wrapper>
          <OrganizationalPage />
        </Wrapper>
      ),
    },
    {
      path: "/services",
      element: (
        <Wrapper>
          <ServicesPage />
        </Wrapper>
      ),
    },
    {
      path: "/property-info",
      element: (
        <Wrapper>
          <PropertyInfoPage />
        </Wrapper>
      ),
    },
    {
      path: "/gov-procurement",
      element: (
        <Wrapper>
          <GovProcurementPage />
        </Wrapper>
      ),
    },
    {
      path: "/annual-cost",
      element: (
        <Wrapper>
          <AnnualCostPage />
        </Wrapper>
      ),
    },
    {
      path: "/annual-cost",
      element: (
        <Wrapper>
          <AnnualCostPage />
        </Wrapper>
      ),
    },
    {
      path: "/vacancies",
      element: (
        <Wrapper>
          <VacanciesPage />
        </Wrapper>
      ),
    },
    {
      path: "/requisites",
      element: (
        <Wrapper>
          <RequisitesPage />
        </Wrapper>
      ),
    },
    {
      path: "/how-to-become-member",
      element: (
        <Wrapper>
          <HowToBecomeMemberPage />
        </Wrapper>
      ),
    },
    {
      path: "/clearing-features",
      element: (
        <Wrapper>
          <ClearingFeaturepage />
        </Wrapper>
      ),
    },
    {
      path: "/clearing-participants",
      element: (
        <Wrapper>
          <ClearingParticipantsPage />
        </Wrapper>
      ),
    },
    {
      path: "/rates",
      element: (
        <Wrapper>
          <RatesPage />
        </Wrapper>
      ),
    },
    {
      path: "/docs",
      element: (
        <Wrapper>
          <DocsPage />
        </Wrapper>
      ),
    },
    {
      path: "/contacts",
      element: (
        <Wrapper>
          <ContactPage />
        </Wrapper>
      ),
    },
    {
      path: "/news-announcement",
      element: (
        <Wrapper>
          <NewsAnnouncementPage />
        </Wrapper>
      ),
    },
    {
      path: "/press-releases",
      element: (
        <Wrapper>
          <PressRealsePage />
        </Wrapper>
      ),
    },
    {
      path: "/media-library",
      element: (
        <Wrapper>
          <MediaLibraryPage />
        </Wrapper>
      ),
    },
    {
      path: "/news-archive",
      element: (
        <Wrapper>
          <NewsArchivePage />
        </Wrapper>
      ),
    },
    {
      path: "/internal-doc",
      element:(
        <Wrapper>
          <InternalDocPage />
        </Wrapper>
      )
    },
    {
      path: "/registration-proof",
      element:(
        <Wrapper>
          <RegistrationProofPage />
        </Wrapper>
      )
    },
    {
      path: "essential-facts",
      element: (
        <Wrapper>
          <EssentialFactsPage />
        </Wrapper>
      )
    },
    {
      path: "rules",
      element: (
        <Wrapper>
          <RulesPage />
        </Wrapper>
      )
    },
    {
      path: "auth-cap-struc",
      element: (
        <Wrapper>
          <AuthCapStructure />
        </Wrapper>
      )
    },
    {
      path: "col-advisory-bodies",
      element: (
        <Wrapper>
          <ColAdvisoryBodiesPage />
        </Wrapper>
      )
    },
    {
      path: "affiliates",
      element: (
        <Wrapper>
          <AffiliatesPage />
        </Wrapper>
      )
    },
    {
      path: "reg-leg-inter-doc",
      element: (
        <Wrapper>
          <RegLegInterDocPage />
        </Wrapper>
      )
    },
    {
      path: "corp-reports",
      element: (
        <Wrapper>
          <CorpReportPage />
        </Wrapper>
      )
    },
    {
      path: "management-risk",
      element: (
        <Wrapper>
          <ManagementRiskPage />
        </Wrapper>
      )
    },
    {
      path: "shareholder-reports",
      element: (
        <Wrapper>
          <ShareHolderReport />
        </Wrapper>
      )
    },
    {
      path: "business-plan",
      element: (
        <Wrapper>
          <BusinesPlanPage />
        </Wrapper>
      )
    },
    {
      path: "audit-reports",
      element: (
        <Wrapper>
          <AuditReportPage />
        </Wrapper>
      )
    },
    {
      path: "kpi",
      element: (
        <Wrapper>
          <KPIPage />
        </Wrapper>
      )
    },
    {
      path: "info-acquis-shares",
      element: (
        <Wrapper>
          <InfoAcquisSharesPage />
        </Wrapper>
      )
    },
    {
      path: "extracts-from-dec-osa-bodies",
      element: (
        <Wrapper>
          <ExtractsFromDecOsaBodiesPage />
        </Wrapper>
      )
    },
    {
      path: "info-pay-management-bodies",
      element: (
        <Wrapper>
          <InfoPayManagementBodiesPage />
        </Wrapper>
      )
    },
    {
      path: "prospectuses",
      element: (
        <Wrapper>
          <ProspectusesPage />
        </Wrapper>
      )
    },
    {
      path: "info-about-dividends",
      element: (
        <Wrapper>
          <InfoAboutDividendsPage />
        </Wrapper>
      )
    },
    {
      path: "announcement-shareholders",
      element: (
        <Wrapper>
          <AnnouncementShareholdersPage />
        </Wrapper>
      )
    },
    {
      path: "legislation",
      element: (
        <Wrapper>
          <LegislationPage />
        </Wrapper>
      )
    },
    {
      path: "doc-forms",
      element: (
        <Wrapper>
          <DocFormsPage />
        </Wrapper>
      )
    },
    {
      path: "online-req-form",
      element: (
        <Wrapper>
          <OnlineReqFormPage />
        </Wrapper>
      )
    },
    {
      path: "online-consultant",
      element: (
        <Wrapper>
          <OnlineConsultantPage />
        </Wrapper>
      )
    },
    {
      path: "faq",
      element: (
        <Wrapper>
          <FAQPage />
        </Wrapper>
      )
    },
    {
      path: "*",
      element: (
       <NotFound />
      ),
    },
  ])
  return (
    <div className="App">
    <RouterProvider router={router} />
    </div>
  );
}

export default App;
