import React, {useState, useEffect} from "react";
import { Carousel, Flex, Table } from "antd";
import mainCar1 from "../../images/main-car-1.jpg";
import mainCar2 from "../../images/main-car-2.jpg";
import mainCar3 from "../../images/main-car-3.jpg";
import mainCar4 from "../../images/main-car-4.jpg";
import mainCar5 from "../../images/main-car-5.jpg";
import mainCar6 from "../../images/main-car-6.jpg";
import mainCar7 from "../../images/main-car-7.jpg";
import mainCar8 from "../../images/main-car-8.jpg";
import mainCarousel1 from "../../images/main-carousel-1.jpg";
import mainCarousel2 from "../../images/main-carousel-2.png";
import mainCarousel3 from "../../images/main-carousel-3.jpg";

import { Col, Row, Button } from "antd";

import { BsBank } from "react-icons/bs";
import { FaHandHoldingUsd } from "react-icons/fa";
import { FaChartLine } from "react-icons/fa6";
import { FaDatabase } from "react-icons/fa";
import { IoMdInformationCircle } from "react-icons/io";
import { FaPeopleGroup } from "react-icons/fa6";
import { MdOutlineSupportAgent } from "react-icons/md";
import { Link } from "react-router-dom";
import getRequest from "../../utiils/getRequest"

import { AiFillCaretUp } from "react-icons/ai";
import { AiFillCaretDown } from "react-icons/ai";

const Main = () => {
  
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(today.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  };

  const getYesterdayDate = () => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
  
    const year = yesterday.getFullYear();
    const month = String(yesterday.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(yesterday.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  };

  const todayFormatted = getTodayDate();

  const yesterdayDate = getYesterdayDate();

  useEffect(() => {
    const fetchDataFromApi = async () => {
      try {
        const resultTdy = await getRequest(`https://cbu.uz/ru/arkhiv-kursov-valyut/json/all/${todayFormatted}/`);
        const resultYst = await getRequest(`https://cbu.uz/ru/arkhiv-kursov-valyut/json/all/${yesterdayDate}/`);
        
        const filteredData = resultTdy.filter(
          (item) => item.Ccy === "USD" || item.Ccy === "EUR" || item.Ccy === "CNY"  || item.Ccy === "KZT"  || item.Ccy ===  "GBP" || item.Ccy === "RUB"
        );

        const filterDataYst = resultYst.filter(
          (item) => item.Ccy === "USD" || item.Ccy === "EUR" || item.Ccy === "CNY" || item.Ccy === "KZT" || item.Ccy ===  "GBP" || item.Ccy === "RUB"
        );

        const transformedData = filteredData.map((item) => {
          
          const correspondingItemYst = filterDataYst.find((yesterdayItem) => yesterdayItem.id === item.id);
        
          return {
            key: item.id.toString(),
            name: item.CcyNm_RU,
            today: item.Rate,
            yesterday: correspondingItemYst ? correspondingItemYst.Rate : null,
            result: item.Rate > correspondingItemYst.Rate ? <AiFillCaretUp style={{color: 'green', alignItems: 'center', fontSize: '20px' }}/> : <AiFillCaretDown style={{color: 'red', fontSize: '20px'}}/>
          };
        });
       
        setTableData(transformedData)
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false);
      }
    };

    fetchDataFromApi();
  }, [])

  const columns = [
    {
      title: "Валютная пара",
      dataIndex: "name",
      align: 'center'
    },
    {
      title: "Вчера",
      dataIndex: "yesterday",
      align: 'center'
    },
    {
      title: "Сегодня",
      dataIndex: "today",
      align: 'center'
    },
    {
      title: "",
      dataIndex: "result",
      align: 'center'
    },
  ]; 

  return (
    <div>
      <div className="carousel-by-main-page">
        <Carousel
          autoplay
          dotPosition="bottom"
          style={{
            height: "700px",
          }}
        >
          <div>
            <div className="car-main-img">
              <img
                src={mainCarousel1}
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "100%",
                  height: "700px",
                  filter: "brightness(50%)",
                }}
                alt="new-carousel-1"
              />
            </div>
            <div className="car-main-con">
              <p style={{ fontSize: 40, color: "white" }}>
                УзРВБ на МБФ: расширение горизонтов и перспективы в мировой
                биржевой индустрии
              </p>
              <Link to="">
                <Button type="primary" size="large">
                  Подробнее
                </Button>
              </Link>
            </div>
          </div>
          <div>
          <div className="car-main-img">
          <img
              src={mainCarousel2}
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                width: "100%",
                height: "700px",
                filter: "brightness(50%)",
              }}
              alt="new-carousel-1"
            />
             </div>
             <div className="car-main-con">
              <p style={{ fontSize: 60, color: "#E93131", fontWeight: 700, margin: 0, fontFamily:" Arial, Helvetica, sans-serif" }}>"ONE CLICK"-</p>
              <p style={{ fontSize: 40, color: "white",  marginTop: 0 }}>
                расчеты <span style={{fontWeight: 800}}> автоматизированы, человечиский фактор </span> отсутствует
              </p>
              <Link to="">
                <Button type="primary" size="large">
                  Подробнее
                </Button>
              </Link>
            </div>
          </div>
          <div>
          <div className="car-main-img">
          <img
              src={mainCarousel3}
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                width: "100%",
                height: "700px",
                filter: "brightness(50%)",
              }}
              alt="new-carousel-1"
            />
          </div>

          <div className="car-main-con">
              <p style={{ fontSize: 40, color: "white" }}>
              УзРВБ и Центральный банк Кореи: укрепление сотрудничества и развитие инфраструктуры рынка капитала
              </p>
              <Link to="">
                <Button type="primary" size="large">
                  Подробнее
                </Button>
              </Link>
            </div>
           
          </div>
        </Carousel>
      </div>
      <div className="cur-ex-feedback">
        <Flex justify="space-around">
          <Flex vertical="true">
            <div style={{ fontSize: "26px" }}> Курс Центрального Банка</div>
            <div>
              <Table
                style={{ marginTop: "20px", width: '70vh' }}
                columns={columns}
                dataSource={tableData}
                pagination={false}
                bordered
              />
            </div>
          </Flex>
          <Flex vertical="true" style={{ width: "50%" }}>
            <div style={{ fontSize: "26px" }}>О нас</div>
            <div style={{ textAlign: "left" }}>
              <blockquote>
                АО «Национальный клиринговый центр» (НКЦ), создано при
                учредительстве АО «Узбекская республиканская валютная биржа» в
                соответствии с требованиями постановления Президента Республики
                Узбекистан от 02.09.2023 №ПП-291 «О дополнительных мерах по
                развитию рынка капитала».
              </blockquote>

              <blockquote>
                НКЦ выполняет функции клиринговой организации и центрального
                контрагента на рынке капитала Республики Узбекистан. Осуществляя
                деятельность центрального контрагента, НКЦ берет на себя риски
                невыполнения сторонами биржевых сделок своих обязательств,
                выступая продавцом для каждого покупателя и покупателем для
                каждого продавца.
              </blockquote>

              <blockquote>
                Деятельность НКЦ в качестве центрального контрагента и по
                осуществлению централизованного клиринга (Clearinghouse)
                регулируется Центральным банком Республики Узбекистан.
              </blockquote>

              <blockquote>
                Основной функцией НКЦ является обеспечение поддержания
                стабильности на обслуживаемых рынках за счет осуществления
                современной, отвечающей международным стандартам системы
                управления рисками и предоставлять услуги, которые позволяют
                участникам биржевых рынков эффективно управлять своими активами.
              </blockquote>

              <blockquote>
                Передача УзРВБ клиринговых функций в отдельный институт стала
                важным этапом в развитии всей инфраструктуры отечественного
                рынка капитала и пост-трейдинговых услуг УзРВБ, разделив
                клиринговые и биржевые риски.
              </blockquote>

              <blockquote>
                НКЦ предоставляют участникам биржевых рынков УзРВБ следующие
                преимущества:
                <ul>
                  <li>
                    НКЦ гарантирует исполнение обязательств перед каждым
                    добросовестным участником по сделкам, заключаемым с ЦК, вне
                    зависимости от исполнения своих обязательств другими
                    участниками рынка;
                  </li>
                  <li>
                    НКЦ избавляет участников от необходимости оценивать риски;
                  </li>

                  <li>
                    Клиринговые услуги НКЦ позволяют участникам эффективно
                    управлять своими активами и уменьшить транзакционные
                    издержки.
                  </li>
                </ul>
              </blockquote>

              <blockquote>
                Стратегической задачей НКЦ является предоставление участникам
                рынка капитала интегрированного клирингового обслуживания,
                предусматривающего использование единого пула обеспечения и
                ведение единых позиций (лимитов) участников в процессе их
                обслуживания на всех биржевых рынках УзРВБ.
              </blockquote>
            </div>
          </Flex>
        </Flex>
        <br />
      </div>
      <div
        className="doubleMain"
        style={{ width: "70%", margin: "auto", marginTop: "80px" }}
      >
        <Row className="">
          <Col className="gutter-row" span={8} style={{ marginBottom: 50 }}>
            <div className="doubleMain-elem">
              <div className="icon-div">
                <BsBank
                  style={{
                    fontSize: 80,
                    position: "relative",
                    background: "#CE1126",
                    padding: "22px",
                    color: "white",
                    borderRadius: "0px 25px 25px 25px",
                  }}
                />
              </div>
              <div className="text-icon">
                <Link className="custom-link" to="/management">О нас</Link>
              </div>
            </div>
          </Col>

          <Col className="gutter-row" span={8} style={{ marginBottom: 50 }}>
            <div className="doubleMain-elem">
              <div className="icon-div">
                <FaHandHoldingUsd
                  style={{
                    fontSize: 80,
                    position: "relative",
                    background: "#CE1126",
                    padding: "22px",
                    color: "white",
                    borderRadius: "0px 25px 25px 25px",
                  }}
                />
              </div>
              <div className="text-icon">
                <Link className="custom-link" to="/clearing-participants">Клиринг</Link>
              </div>
            </div>
          </Col>

          <Col className="gutter-row" span={8} style={{ marginBottom: 50 }}>
            <div className="doubleMain-elem">
              <div className="icon-div">
                <FaChartLine
                  style={{
                    fontSize: 80,
                    position: "relative",
                    background: "#CE1126",
                    padding: "22px",
                    color: "white",
                    borderRadius: "0px 25px 25px 25px",
                  }}
                />
              </div>
              <div className="text-icon">
                <Link className="custom-link" to="/management-risk">Управление рисками</Link>
              </div>
            </div>
          </Col>

          <Col className="gutter-row" span={8} style={{ marginBottom: 50 }}>
            <div className="doubleMain-elem">
              <div className="icon-div">
                <FaDatabase
                  style={{
                    fontSize: 80,
                    position: "relative",
                    background: "#CE1126",
                    padding: "22px",
                    color: "white",
                    borderRadius: "0px 25px 25px 25px",
                  }}
                />
              </div>
              <div className="text-icon">
                <Link className="custom-link" to="/#">Казначейство</Link>
              </div>
            </div>
          </Col>

          <Col className="gutter-row" span={8} style={{ marginBottom: 50 }}>
            <div className="doubleMain-elem">
              <div className="icon-div">
                <IoMdInformationCircle
                  style={{
                    fontSize: 80,
                    position: "relative",
                    background: "#CE1126",
                    padding: "22px",
                    color: "white",
                    borderRadius: "0px 25px 25px 25px",
                  }}
                />
              </div>
              <div className="text-icon">
                <Link className="custom-link" to="/news-announcement">Новости</Link>
              </div>
            </div>
          </Col>

          <Col className="gutter-row" span={8} style={{ marginBottom: 50 }}>
            <div className="doubleMain-elem">
              <div className="icon-div">
                <FaPeopleGroup
                  style={{
                    fontSize: 80,
                    position: "relative",
                    background: "#CE1126",
                    padding: "22px",
                    color: "white",
                    borderRadius: "0px 25px 25px 25px",
                  }}
                />
              </div>
              <div className="text-icon">
                <Link className="custom-link" to="/#">Международное сотрудничество</Link>
              </div>
            </div>
          </Col>

          <Col className="gutter-row" span={8} style={{ marginBottom: 50 }}>
            <Link to="faq">
              <div className="doubleMain-elem">
                <div className="icon-div">
                  <MdOutlineSupportAgent
                    style={{
                      fontSize: 80,
                      position: "relative",
                      background: "#CE1126",
                      padding: "22px",
                      color: "white",
                      borderRadius: "0px 25px 25px 25px",
                    }}
                  />
                </div>
                <div className="text-icon">
                  <Link className="custom-link" to="/online-req-form">Обратная связь</Link>
                </div>
              </div>
            </Link>
          </Col>

          {/* <Col className="gutter-row" span={8} style={{ marginBottom: 20 }}>
            <div className="doubleMain-elem">Клиринг </div>
          </Col>

          <Col className="gutter-row" span={8} style={{ marginBottom: 20 }}>
            <div className="doubleMain-elem">Управление рисками</div>
          </Col>

          <Col className="gutter-row" span={8} style={{ marginBottom: 20 }}>
            <div className="doubleMain-elem">Казначейство</div>
          </Col>

          <Col className="gutter-row" span={8} style={{ marginBottom: 20 }}>
            <div className="doubleMain-elem">Новости</div>
          </Col>

          <Col className="gutter-row" span={8} style={{ marginBottom: 20 }}>
            <div className="doubleMain-elem">Международное сотрудничество</div>
          </Col>

          <Col className="gutter-row" span={8} style={{ marginBottom: 20 }}>
            <div className="doubleMain-elem">Обратная связь </div>
          </Col> */}
        </Row>
      </div>

      <div
        className="principles"
        style={{ width: "80%", margin: "auto", marginTop: "50px" }}
      >
        <p
          style={{
            display: "flex",
            fontSize: "28px",
            fontWeight: 500,
            marginBottom: "15px",
          }}
        >
          Полезные ресурсы
        </p>
        <Carousel
          autoplay
          slidesToShow={3}
          dotPosition="bottom"
          style={{ padding: "10px", height: "200px" }}
        >
          <div className="car-div">
            <a href="https://www.uz/" target="_blank" rel="noreferrer">
              <img src={mainCar1} alt="image1" />
              <p className="p-car">www.uz</p>
            </a>
          </div>

          <div className="car-div">
            <a href="https://lex.uz/" target="_blank" rel="noreferrer">
              <img src={mainCar2} alt="image2" />
              <p className="p-car">lex.uz</p>
            </a>
          </div>
          <div className="car-div">
            <a href="https://cbu.uz/ru/" target="_blank" rel="noreferrer">
              <img src={mainCar3} alt="image3" />
              <p className="p-car">cbu.uz</p>
            </a>
          </div>
          <div className="car-div">
            <a href="https://gov.uz/ru/" target="_blank" rel="noreferrer">
              <img src={mainCar4} alt="image4" />
              <p className="p-car">gov.uz</p>
            </a>
          </div>
          <div className="car-div">
            <a href="https://uzse.uz/" target="_blank" rel="noreferrer">
              <img src={mainCar5} alt="image5" />
              <p className="p-car">uzse.uz</p>
            </a>
          </div>
          <div className="car-div">
            <a
              href="https://new.openinfo.uz/home"
              target="_blank"
              rel="noreferrer"
            >
              <img src={mainCar6} alt="image6" />
              <p className="p-car">openinfo.uz</p>
            </a>
          </div>
          <div className="car-div">
            <a href="https://president.uz/uz" target="_blank" rel="noreferrer">
              <img src={mainCar7} alt="image7" />
              <p className="p-car">president.uz</p>
            </a>
          </div>
          <div className="car-div">
            <a href="https://my.gov.uz/ru" target="_blank" rel="noreferrer">
              <img src={mainCar8} alt="image8" />
              <p className="p-car">my.gov.uz</p>
            </a>
          </div>
        </Carousel>
      </div>
      <br />
    </div>
  );
};

export default Main;
