import React from 'react'
import UnderConstruction from '../../components/underConstruction/underConstruction'

function InfoAboutDividendsPage() {
  return (
    <>
        <UnderConstruction />
    </>
  )
}

export default InfoAboutDividendsPage