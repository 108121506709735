import React from 'react'
import pdfFile from "../../pdfFiles/pdf2.pdf";
import { AiFillFilePdf } from "react-icons/ai";

function RegistrationProofPage() {
  return (
    <div style={{padding: '20px', minHeight: '65vh'}}>
        <h3>Сертификат о регистрации АО "Национальный клиринговый центр"</h3>
        <a href={pdfFile} target="_blank" rel="pdf-file noreferrer">
        <AiFillFilePdf className="pdf-file" style={{color: '#E93131'}}/>
      </a>
    </div>
  )
}

export default RegistrationProofPage