import React from 'react'
import UnderConstruction from '../../components/underConstruction/underConstruction'

function CorpReportPage() {
  return (
    <>
    <UnderConstruction/>
    </>
  )
}

export default CorpReportPage